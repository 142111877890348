import React from 'react'

import { Layout } from '../components/common/Layout'
import { PageTitles } from '../vars'

export default function NotFoundPage() {
  return (
    <Layout currentPageTitle={PageTitles.NotFound} darkBg={false}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}
